<template>
  <div class="relative group text-gray-500">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 border-blue-100" fill="none" viewBox="0 0 24 24"
      stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
    </svg>

    <div
      :class="[{ 'bottom-full': !invertY, 'top-full': invertY },'hidden group-hover:block absolute left-full p-2 -mb-5 ml-1 rounded bg-gray-50 border border-blue-100 shadow z-50']">
      <!-- Flecha -->
      <div :class="[{'bottom-0': !invertY, 'top-0': invertY},'absolute -left-2']">
        <div class="w-2 overflow-hidden inline-block">
          <div class="
              h-4
              bg-gray-50
              border-blue-100
              shadow
              -rotate-45
              transform
              origin-top-right
            "></div>
        </div>
      </div>
      <div class="w-20 h-20">
        <img class="w-20 h-20 object-contain" :src="repuesto.image" :alt="`Foto de ${repuesto.nombre}`" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    repuesto: {
      type: Object,
      required: true,
    },
    invertY: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {};
  },
};
</script>
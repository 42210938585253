<template>
  <div class="relative w-2/3">
    <input
      type="text"
      class="w-full pr-5 pl-1 border"
      ref="input"
      :value="value"
      @keyup="$emit('update:value', $event.target.value)"
    />
    <div
      v-if="value"
      class="absolute right-1 top-0.5 cursor-pointer"
      @click="$emit('update:value', '')"
    >
      <icon-x class="w-3 h-3" />
    </div>
  </div>
</template>

<script>
import IconX from "@/assets/icons/X.vue";
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';

export default {
  components: {
    IconX,
  },
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
  },
  emit: ["update:value"],
  setup() {
    const input = ref(null)
    
    onMounted(() => {
        input.value.focus();
    })
    return { input }
  },
};
</script>
<template>
  <div class="w-full max-w-screen-2xl mx-auto p-4">
    <div class="flex">
      <go-back-arrow @click="goBack">
        Volver
      </go-back-arrow>
    </div>


    <!-- Producto -->
    <div class="mt-4 lg:hidden">
      <div class="flex gap-x-4 items-center">
        <div class="h-20 w-20 flex-shrink-0">
          <img :src="product.image" alt="" />
        </div>
        <div class="text-2xl">{{ product.text }}</div>
      </div>
    </div>

    <div class="w-full lg:mt-4 flex  flex-col md:flex-row ">
      <!-- Carousel -->
      <div class="mt-10 relative">
        <div v-if="product.despieces.length > 0 && !loading">
          <carousel-despiece :images="product.despieces" @changeActive="idDespieceActivo = $event" />
        </div>
        <div v-else-if="product.despieces.length == 0 && !loading">
          No se encontraron despieces para este producto
        </div>
      </div>

      <!-- Partes -->
      <div class="p-4 flex flex-1">
        <despiece-table v-if="getDespieceActive" :product="product" :productName="product.text"
          :despiece="getDespieceActive" />
      </div>

    </div>
  </div>


</template>

<script>
import axios from "axios";
import router from "@/router/index";
import { computed, reactive, ref } from "@vue/reactivity";
import { useRoute } from "vue-router";

// import { despiece } from "@/utils/mock"
import { apiGetProducto, apiGetDespiece } from "@/utils/endpoints";
import { parseProduct, parseDespiece } from "@/utils/parsers";

import CarouselDespiece from "../components/DespieceCarousel.vue";
import DespieceTable from "../components/DespieceTable.vue";
import GoBackArrow from "../components/elements/GoBackArrow.vue";

export default {
  name: "Replacement",
  components: {
    CarouselDespiece,
    DespieceTable,
    GoBackArrow
  },
  setup() {
    const route = useRoute();
    let loading = ref(true);
    let productId = route.params.productId;

    let idDespieceActivo = ref("");

    let product = reactive({
      despieces: [],
    });

    // Get de Producto
    axios.get(apiGetProducto(productId)).then((data) => {
      // [0] porque data.data viene como un array
      Object.assign(product, parseProduct(data.data)[0]);
    });

    // Get de Despiece
    axios
      .get(apiGetDespiece(productId))
      .then((data) => {
        let despieceArr = parseDespiece(data.data);
        product.despieces.push(...despieceArr);
      })
      .catch((err) => console.log(err))
      .then(() => {
        loading.value = false;
      });

    // Get Despiece Activo
    const getDespieceActive = computed(() => {
      return product.despieces.find(
        (item) => item.id === idDespieceActivo.value
      );
    });

    const goBack = () => {
      router.go(-1)
    }
    return {
      product,
      idDespieceActivo,
      getDespieceActive,
      loading,
      goBack
    };
  },
};
</script>

<style>
</style>

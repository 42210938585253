<template>
  <div v-if="repuesto.replace.length > 0" class="flex relative group">
    <!-- Ícono -->
    <icon-question class="text-gray-500 h-3.5 w-3.5" />

    <!-- Tooltip -->
    <div
      class="
        p-2
        hidden
        group-hover:block
        absolute
        left-full
        rounded
        border border-black
        bg-gray-50
        max-w-screen-md
        z-50
      "
    >
      <span class="font-bold">Reemplaza:</span>
      <div
        v-for="replace in repuesto.replace"
        :key="replace"
        class="flex truncate"
        :title="replace"
      >
        - {{ replace }}
      </div>
    </div>
  </div>
</template>

<script>
import IconQuestion from "@/assets/icons/Question.vue";

export default {
  components: {
    IconQuestion,
  },
  props: {
    repuesto: {
      type: Object,
      required: true,
    },
  },
};
</script>
<template>
  <!-- Agrandar -->
  <div class="my-1 w-full flex justify-center text-xl">
    <div @click="openZoomModal" class="flex items-center gap-x-1 hover:underline cursor-pointer text-gray-600">
      <full-screen class="w-6 h-6" />
      <span>
        Agrandar despiece
      </span>
    </div>
  </div>


  <img :src="img" alt="Despiece" :class="'invisible absolute -top-full -left-full select-none object-contain'"
    id="clone" @load="makeMapster();" />
  <!-- Imagen -->
  <div :style="`width:${width}px;height:${height}px;max-width:${width}px;max-height:${height}px;`"
    class="border flex justify-center items-center">
    <div v-if="!loading && !modalZoomOpen" id="map" class="mx-auto select-none z-40 flex justify-center items-start">
      <img :src="img" alt="Despiece" usemap="#locations" :class="'select-none object-contain'" id="locations-map" />
      <map name="locations" id="locations" class="pointer-events-none">
        <area v-for="(item, index) in areas" :key="index" class="tooltip cursor-pointer" :shape="item.shape"
          :alt="item.id" :coords="getCoords(item)" @mouseover="hoverArea(item.ref)" @mouseleave="hoverArea(null)"
          @click="$emit('update:indexActive', item.ref)" :data-key="
            item.withCoords
              ? indexActive !== null || indexHover !== null
                ? (indexHover === null && indexActive == item.ref) ||
                  (indexHover == item.ref)
                  ? 'active'
                  : 'inactive'
                : 'normal'
              : 'invisible'
          " :title="item.id" />
      </map>
    </div>
  </div>

  <div class="flex justify-center font-bold text-3xl">{{ despieceName }}</div>

  <Modal v-if="modalZoomOpen" @cancel="closeZoomModal" class="z-50">
    <template v-slot:title>
      {{ despieceName }}
    </template>
    <template v-slot:body>
      <div class="h-full w-full">
        <div v-if="!loading" id="map" class="h-full w-full mx-auto select-none z-40 flex justify-center items-center">
          <img :src="img" alt="Despiece" usemap="#locations" :class="'select-none object-contain'"
            id="modal-locations-map" />
          <map name="locations" id="locations" class="pointer-events-none">
            <area v-for="(item, index) in areas" :key="index" class="tooltip cursor-pointer" :shape="item.shape"
              :alt="item.id" :coords="getCoords(item)" @mouseover="hoverArea(item.ref)" @mouseleave="hoverArea(null)"
              @click="$emit('update:indexActive', item.ref); closeZoomModal()" :data-key="
                item.withCoords
                  ? indexActive !== null || indexHover !== null
                    ? (indexHover === null && indexActive == item.ref) ||
                      (indexHover == item.ref)
                      ? 'active'
                      : 'inactive'
                    : 'normal'
                  : 'invisible'
              " :title="item.id" />
          </map>
        </div>

        <div
          class="w-full p-2 fixed bottom-0 left-0 bg-main hover:bg-main-darker text-center text-white text-2xl cursor-pointer "
          @click="closeZoomModal">
          CERRAR
        </div>
      </div>
    </template>
  </Modal>

</template>

<script>
import { ref } from "vue";
import { toRef, computed } from "@vue/reactivity";
import { nextTick, onMounted, watch } from "@vue/runtime-core";

import $ from "jquery";
import mapster from "imagemapster";

import FullScreen from "@/assets/icons/FullScreen.vue";
import Modal from "@/components/elements/Modal"

export default {
  name: "DespieceMap",
  components: {
    FullScreen,
    Modal
  },
  props: {
    indexActive: { type: [String, Number, null], required: true },
    indexHover: { type: [String, Number, null], required: true },
    img: { type: String, required: true },
    areas: { type: Array, required: false },
    despieceName: { type: String, required: true }
  },
  setup(props, { emit }) {
    // Cuando cambia la imagen actualiza el map
    let img = toRef(props, "img");
    let width = ref();
    let height = ref();
    let loading = ref(false);

    // watch(
    //   () => img.value,
    //   () => {
    //     // loading.value = true;
    //     nextTick(() => {
          
    //       nextTick(() => {
    //         // makeMapster();
    //       })
    //     })
    //   }
    // );
  
    onMounted(() => {
      calcDimension();
      window.addEventListener("resize", makeMapster);
      // makeMapster();
    });

    let isFullSize = ref(false);
    const makeMapster = () => {
      let id = modalZoomOpen.value ? '#modal-locations-map' : '#locations-map';
      $(id).mapster({
        stroke: true,
        staticState: true,
        // strokeColor: "059669",
        mapKey: "data-key",
        render_highlight: {
          fillOpacity: 0.2,
        },
        render_select: {
          fillOpacity: 0.4,
        },
        areas: [
          {
            key: "inactive",
            stroke: true,
            strokeColor: "FFF",
            fillColor: "FFF",
            fillOpacity: 0,
            strokeWidth: 0,
            render_highlight: {
              fillOpacity: 0,
            },
            render_select: {
              fillOpacity: 0,
            },
          },
          {
            key: "normal",
            stroke: true,
            strokeColor: "7AA8F1",
            fillColor: "7AA8F1",
            fillOpacity: 1,
            strokeWidth: 4,
            render_highlight: {
              fillOpacity: 1,
            },
            render_select: {
              fillOpacity: 1,
            },
          },
          {
            key: "active",
            stroke: true,
            strokeWidth: 6,
            strokeColor: "0062ff",
            fillColor: "EEEEEE",
            fillOpacity: 1,
            render_highlight: {
              fillOpacity: 1,
            },
            render_select: {
              fillOpacity: 1,
            },
          },
        ],
      });


      calcDimension();

      let imageCloned = document.getElementById('clone');
      let isBigger = imageCloned.naturalWidth > width.value;
      let isPortrait = imageCloned.naturalHeight >= imageCloned.naturalWidth;
      let borderFix = 2;
      let newWidth = width.value - borderFix;
      let w = isBigger && isPortrait ? newWidth : 0;
      $(id).mapster("resize", 0, w);
    };

    let indexHover = toRef(props, "indexHover");
    const hoverArea = (index) => {
      emit("update:indexHover", index);
    };

    watch(
      () => indexHover.value,
      () => {
        makeMapster();
      }
    );

    const getCoords = (item) => {
      let x = item.x;
      let y = item.y;
      let shape = item.shape;

      if (shape === "rect") {
        let height = item.height;
        let width = item.width;
        return `${x - width},${y - height},${x + width}, ${y + height}`;
      } else {
        let radio = item.radio;
        return `${x},${y},${radio}`;
      }
    };

    const calcDimension = () => {
      if (isFullSize.value) {
        width.value = window.innerWidth > 1300 ? 1300 : 600;
        height.value = window.innerWidth > 1300 ? 1300 : 600;
      } else {
        width.value = window.innerWidth > 1300 ? 500 : 400;
        height.value = window.innerWidth > 1300 ? 500 : 400;
      }
    }


    // Modal
    let modalZoomOpen = ref(false);
    const openZoomModal = () => {
      modalZoomOpen.value = true;
      isFullSize.value = true;

      // Oculto el overflow de Body para evitar doble scroll
      document.body.classList.add(
        'overflow-hidden'
      );
      nextTick(() => {
        makeMapster();
      })

    }
    const closeZoomModal = () => {
      modalZoomOpen.value = false;
      isFullSize.value = false;
      document.body.classList.remove(
        'overflow-hidden'
      );
      nextTick(() => {
        makeMapster();
        nextTick(() => {
          makeMapster();
        })
      })
    }

    return { mapster, getCoords, modalZoomOpen, openZoomModal, closeZoomModal, hoverArea, isFullSize, makeMapster, loading, height, width };
  },
};
</script>

<style>
</style>
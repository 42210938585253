<template>
  <div class="w-full flex flex-1 flex-col lg:flex-row items-center lg:items-start">
    <div id="despieceMap" class="pt-5 px-4 flex-wrap-0">
      <replacement-map :indexHover="indexHover" :indexActive="indexActive" :areas="despiece.parts" :img="despiece.image"
        :despieceName="despiece.text" @update:indexActive="changeIndexActive" @update:indexHover="changeHoverActive" />
    </div>

    <div class="w-full mt-5 lg:mt-10 flex flex-col flex-1">
      <!-- Producto -->
      <div class="hidden lg:block">
        <div class="flex gap-x-4 items-center">
          <div class="h-20 w-20 flex-shrink-0">
            <img :src="product.image" alt="" />
          </div>
          <div class="text-2xl">{{ product.text }}</div>
        </div>
      </div>

      <!-- Tabla -->
      <section class="sm:grid grid-cols-1">
        <div class="z-10 mt-1 w-full mx-auto">
          <!-- Tabla -->
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded" id="tabla">
            <div class="block w-full h-96 md:h-100 overflow-y-scroll">
              <table id="replacement-table" class="items-center bg-transparent w-full border-collapse">
                <!-- Header -->
                <thead>
                  <tr>
                    <th v-for="(column, index) in columns" :key="index"
                      class="bg-gray-50 text-gray-500 align-middle border border-solid border-gray-100 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      <div class="flex items-center gap-x-1">
                        <span>{{ column.text }}</span>
                        <div v-if="column.search" :class="[{ 'text-main': searchActive[column.model] }]"
                          @click="toggleSearch(column.model)">
                          <icon-search class="w-3 h-3 cursor-pointer" />
                        </div>
                      </div>
                      <div v-if="column.search && searchActive[column.model]">
                        <input-busqueda v-model:value="searchTerm[column.model]" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <!-- Repuestos -->
                <tbody>
                  <!-- @click="indexActive = repuesto.ref" -->
                  <tr v-for="(repuesto, index) in repuestosComputed" :key="repuesto.id" :data-ref="repuesto.ref" :class="[
                    {
                      'even:bg-gray-50': !indexHover && !refActive,
                      'bg-blue-100': repuesto.ref
                        && (refActive == repuesto.ref),
                      'bg-yellow-100': repuesto.ref
                        && (indexHover == repuesto.ref),
                      'original': repuesto.original && !tieneAlternativo(repuesto.ref),
                      'pt-2': repuesto.original && isPrimero(repuesto.ref, repuesto.id),
                      'pb-2': isUltimo(repuesto.ref, repuesto.id)
                    },
                    'text-lg',
                  ]" @mouseover="indexHover = repuesto.ref" @mouseleave="indexHover = null">
                    <!-- Referencia -->
                    <td class>{{ repuesto.ref }}</td>

                    <!-- Código -->
                    <td class="h-full">
                      <div class="flex items-center gap-x-1">
                        <!-- Código Span -->
                        <span class="cursor-pointer hover:underline" @click="goToRepuesto(repuesto.id)">{{ repuesto.code
                        }}</span>

                        <!-- Reemplazos -->
                        <tooltip-reemplazo :repuesto="repuesto" />
                      </div>
                    </td>
                    <!-- Nombre -->
                    <td class="cursor-pointer hover:underline" @click="goToRepuesto(repuesto.id)">
                      <div class="flex items-center whitespace-wrap gap-x-1">
                        <span>{{ repuesto.nombre }}</span>
                        <span v-if="!repuesto.original" class="text-xs text-gray-400">(alternativo)</span>

                        <!-- Imagen del repuesto -->
                        <tooltip-imagen-repuesto :invertY="index < 3" :repuesto="repuesto" />
                      </div>
                    </td>
                    <td>${{ repuesto.precio }}</td>
                    <td>
                      <div>
                        <input-cantidad v-if="repuesto.stock && repuesto.precio" v-model:value="repuesto.cantidad"
                          :min="0" :max="repuesto.stock - (getCarritoById[repuesto.id] || 0)" class="h-6" />
                        <!-- :max="20 - (getCarritoById[repuesto.id] || 0)" -->
                        <div v-else class="h-6 flex items-center text-lg text-gray-400">Sin stock</div>
                      </div>
                      <div v-if="repuesto.stock && getCarritoById[repuesto.id]" class="text-gray-500">
                        En carrito:
                        {{ getCarritoById[repuesto.id] }}
                      </div>
                    </td>
                  </tr>

                  <!-- Length == 0 -->
                  <tr v-if="searchTerm && repuestosComputed.length == 0 && !loadingRepuestos" class="text-gray-400">
                    <td colspan="5">
                      <div class="p-2">No hay repuestos disponibles</div>
                    </td>
                  </tr>

                  <!-- Loading -->
                  <tr v-if="loadingRepuestos">
                    <td colspan="5">
                      <div class="p-2 text-gray-400">Cargando...</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Botón carrito -->
          <div class="w-full flex justify-end" v-if="repuestos.some((item) => item.cantidad > 0)">
            <div
              class="bg-blue-500 hover:bg-blue-400 transition duration-400 cursor-pointer text-white rounded py-2 px-4 text-center"
              @click="goToCheckout">Ir a carrito</div>
          </div>
        </div>
      </section>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import { computed, reactive, ref, toRef } from "@vue/reactivity";
import { useStore } from "vuex";
import { watch } from "@vue/runtime-core";
import router from "@/router/index";

import { apiGetRepuestosByDespiece } from "@/utils/endpoints";
import { parseRepuestosTable } from "@/utils/parsers";

import ReplacementMap from "./DespieceMap.vue";
import InputCantidad from "./elements/InputCantidad.vue";
import InputBusqueda from "./elements/InputBusqueda.vue";
import IconSearch from "../assets/icons/IconSearch.vue";
import TooltipReemplazo from "@/components/elements/TooltipReemplazo";
import TooltipImagenRepuesto from "@/components/elements/TooltipImagenRepuesto";

export default {
  components: {
    ReplacementMap,
    InputCantidad,
    InputBusqueda,
    IconSearch,
    TooltipReemplazo,
    TooltipImagenRepuesto,
  },
  name: "ProductTable",
  props: {
    despiece: { type: Object, required: true },
    productName: { type: String, required: false },
    product: { type: Object, required: true }
  },
  setup(props) {
    const store = useStore();
    let carrito = computed(() => store.state.carrito);

    // Configuración tabla
    const columns = [
      { text: "", search: true, model: "ref" },
      { text: "Código", search: true, model: "id" },
      { text: "Descripción", search: true, model: "description" },
      { text: "Precio", search: false },
      { text: "Comprar", search: false },
    ];
    let searchTerm = reactive({
      ref: "",
      id: "",
      description: "",
    });
    let searchActive = reactive({
      ref: false,
      id: false,
      description: false,
    });

    let productName = toRef(props, "productName");
    let despiece = toRef(props, "despiece");
    let repuestos = ref([]);
    let indexActive = ref(null);
    let indexHover = ref(null);
    let loadingRepuestos = ref(false);

    let idDespiece = ref(despiece.value.id);

    // Si hay cambio en el despiece, limpia los filtros y scrollea al despiece
    watch(
      () => despiece.value,
      () => {
        indexActive.value = null;
        indexHover.value = null;
        refActive.value = null;
        Object.keys(searchTerm).forEach((key) => (searchTerm[key] = ""));
        Object.keys(searchActive).forEach((key) => (searchActive[key] = false));
        // scrollTo("despieceMap");
        idDespiece.value = despiece.value.id;
        getRepuestos();
      }
    );

    const getRepuestos = () => {
      loadingRepuestos.value = true;
      repuestos.value = repuestos.value.splice();
      axios.get(apiGetRepuestosByDespiece(idDespiece.value)).then((data) => {
        repuestos.value = parseRepuestosTable(data.data);
        loadingRepuestos.value = false;
      });
    };
    getRepuestos();

    const getCarritoById = computed(() => {
      return carrito.value.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.cantidad }),
        {}
      );
    });

    // Ir a carrito
    const goToCheckout = () => {
      let productos = repuestos.value
        .map((item) => {
          return {
            ...item,
            producto: despiece.value.text + " - " + productName.value,
          };
        })
        .filter((item) => item.cantidad);
      // store.commit("reset");
      // console.log(productos);
      store.commit("addProducts", productos);
      router.push({ name: `Checkout` });
    };
    const goToRepuesto = (id) => {
      router.push({ path: `/repuesto/${id}` });
    };

    // Repuestos computados para la tabla
    const repuestosComputed = computed(() => {
      let repuestosToFilter = repuestos.value;

      Object.keys(searchTerm).map((key) => {
        if (searchTerm[key]) {
          repuestosToFilter = repuestosToFilter.filter((repuesto) => {
            let regex = new RegExp(
              `${searchTerm[key].toString().toLowerCase()}`
            );
            let value = repuesto[key].toString().toLowerCase();
            return regex.test(value);
          });
        }
      });
      return repuestosToFilter;
    });

    // Handler de click en Despiece
    let refActive = ref("");
    const changeIndexActive = (ref) => {
      refActive.value = ref;
      scrollToRef(ref);
    };

    const changeHoverActive = (ref) => {
      indexHover.value = ref;
      scrollToRef(ref);
    };

    const scrollToRef = (ref) => {
      document.querySelectorAll(`[data-ref='${ref}']`)[0].scrollIntoView({ behavior: "smooth", block: "end", });
    };

    // Funcionalidad ícono lupa al lado del TH
    const toggleSearch = (model) => {
      searchActive[model] = !searchActive[model];
      searchTerm[model] = "";
    };

    const tieneAlternativo = (ref) => {
      return repuestosComputed.value.filter(r => r.ref == ref).length > 1
    }

    const isPrimero = (ref, id) => {
      let repuestosClone = [...repuestosComputed.value];
      return repuestosClone.find(r => r.ref == ref).id == id
    }
    const isUltimo = (ref, id) => {
      let repuestosClone = [...repuestosComputed.value];
      return repuestosClone.reverse().find(r => r.ref == ref).id == id;
    }

    return {
      columns,
      indexHover,
      indexActive,
      goToCheckout,
      goToRepuesto,
      carrito,
      store,
      getCarritoById,
      searchTerm,
      repuestos,
      repuestosComputed,
      changeIndexActive,
      searchActive,
      changeHoverActive,
      toggleSearch,
      tieneAlternativo,
      isPrimero,
      isUltimo,
      refActive,
      loadingRepuestos
    };
  },
};
</script>

<style scoped>
#replacement-table tr td,
#replacement-table tr th {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

#replacement-table tr.original td,
#replacement-table tr.original th {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

#replacement-table tr.pt-2 td {
  padding-top: 0.3rem;
}

#replacement-table tr.pb-2 td {
  padding-bottom: 0.3rem;
}
</style>

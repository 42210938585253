<template>
  <div id="splide-despiece" class="pt-5">
    <Splide :options="splideOptions" @splide:active="clickSplide">
      <SplideSlide v-for="(image, index) in images" :key="index" class="relative">
        <img :src="image.image" alt="Sample 1" class="p-8 object-contain" />
        <div class="w-full p-2 flex justify-center absolute bottom-0 bg-white bg-opacity-80 border-t text-base">
          {{ image.text }}
        </div>
      </SplideSlide>
    </Splide>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { toRef, computed } from "@vue/reactivity";

export default {
  name: "CarouselProduct",
  props: {
    images: { type: Array, required: true },
  },
  components: {
    Splide,
    SplideSlide,
  },
  emits: ["changeActive"],
  setup(props, { emit }) {
    const images = toRef(props, "images");

    const splideOptions = computed(() => {
      return {
        fixedWidth: 150,
        fixedHeight: 150,
        gap: 30,
        rewind: true,
        drag: false,
        pagination: false,
        cover: true,
        focus: "center",
        isNavigation: true,
        updateOnMove: true,
        trimSpace: true,
        speed: 200,
        arrows: "slider",
        direction: 'ttb',
        paginationDirection: 'ltr',
        height: 520,
        breakpoints: {
          768: {
            direction: 'ltr',
            width: 768,
            fixedWidth: 100,
            fixedHeight: 150,
          },
        },
      }
    });


    const clickSplide = (slide) => {
      let index = slide.index;
      let id = images.value[index].id;
      emit("changeActive", id);
    };

    return {
      splideOptions,
      clickSplide,
    };
  },
};
</script>

<style>
#splide-despiece .splide__track {
  width: 90%;
  margin: 0 auto;
}

#splide-despiece .splide__slide {
  /* opacity: 0.2; */
  border: none !important;
  margin: 0 1.2rem;
  border: 1px solid rgba(229, 231, 235, 1) !important;
}

#splide-despiece .splide__slide.is-active {
  opacity: 1;
  border: 2px solid #438D38 !important;
  border-radius: 2%
}

/* .splide__arrow {
  background: none !important;
} */
@media (min-width: 768px) {
  #splide-despiece .splide__arrow.splide__arrow--prev {
    /* left: -0.5rem !important; */
    top: -2.5em !important;
    margin: 0 0.6rem;
  }

  #splide-despiece .splide__arrow.splide__arrow--next {
    /* right: -0.5rem !important; */
    bottom: -2.5em !important;
    margin: 0 0.6rem;
  }
}
</style>